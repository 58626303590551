
import React, { useReducer, createContext, useEffect, useState } from "react"

const initialState = {
  isCartOpen: false,
  items: []
};

export const CartStateContext = createContext();
export const CartDispatchContext = createContext();

const reducer = (state=initialState, action) => {
  
  switch (action.type) {
    case "TOGGLE_CART_POPUP":
      return {
        ...state,
        isCartOpen: !state.isCartOpen
      };
    case "ADD_TO_CART":
      const id = action.payload.cartItem.id
            const itemExist = state.items.some((item) => item.id === id)

            if (!itemExist) {
                return {
                    ...state,
                    items: [...state.items, action.payload.cartItem]
                }
            } else {
                return {
                    ...state,
                    items: state.items.map(item => {
                        if (item.id === id) {
                            return {
                                ...item,
                                quantity: item.quantity + 1
                            };
                        } else {
                            return item;
                        }
                    })
                }
            }

    case "REMOVE_FROM_CART":
      return {
        ...state,
        items: state.items.map(item => {
            if (item.id === action.payload.cartItem.id) {
                return {
                    ...item,
                    quantity: item.quantity - 1
                };
            } else {
                return item;
            }
        }).filter(item => item.quantity > 0)
    };

    case "CLEAR_CART":
      return {
        ...state,
        items: state.items.map(item => {
            if (item.id === action.payload.cartItem.id) {
                return {
                    ...item,
                    quantity: 0
                };
            } else {
                return item;
            }
        }).filter(item => item.quantity > 0)
      };
    
    case "DELETE_CART":
      return {
                ...state,
                ...initialState
      };
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};

export const toggleCartPopup = (dispatch) => {
  return dispatch({
    type: "TOGGLE_CART_POPUP"
  });
};

export const addToCart = (dispatch, cartItem) => {
  return dispatch({
    type: "ADD_TO_CART",
    payload: {
      cartItem: cartItem
    }
  });
};

export const removeFromCart = (dispatch, cartItem) => {
  return dispatch({
    type: "REMOVE_FROM_CART",
    payload: {
      cartItem: cartItem
    }
  });
};

export const clearCart = (dispatch, cartItem) => {
  return dispatch({
    type: "CLEAR_CART",
    payload: {
      cartItem: cartItem
    }
  });
};


export const deleteCart = (dispatch, cartItem) => {
  return dispatch({
    type: "DELETE_CART"
  });
};



const CartProvider = ({ children }) => {

  var dataCartItems = JSON.parse(localStorage.getItem('cartItems')) || {}
  // var dataCartItemsNew = dataCartItems.items.filter(item => item.quantity>0)
  // const [persistedCartItems, setPersistedCartItems] = useState(dataCartItemsNew)
  const [persistedCartItems, setPersistedCartItems] = useState(dataCartItems.items)

  const persistedCartState = {
    isCartOpen: false,
    items: persistedCartItems || []
  };
  
  const [state, dispatch] = useReducer(reducer, persistedCartState);

  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(state))
    setPersistedCartItems(state.items);
 }, [state]);
  
  return (
    <CartDispatchContext.Provider value={dispatch}>
      <CartStateContext.Provider value={state}>
        {children}
      </CartStateContext.Provider>
    </CartDispatchContext.Provider>
  );
};

// const CartProvider = ({ children }) => {

//   var dataCartItems = JSON.parse(localStorage.getItem('cartItems')) || []
//   var dataCartItemsNew = dataCartItems.filter(item => item.quantity>0)
//   const [persistedCartItems, setPersistedCartItems] = useState(dataCartItemsNew)

//   const persistedCartState = {
//     isCartOpen: false,
//     items: persistedCartItems || []
//   };
  
//   const [state, dispatch] = useReducer(reducer, persistedCartState);

//   useEffect(() => {
//     setPersistedCartItems(state.items);
//     localStorage.setItem("cartItems", JSON.stringify(persistedCartItems))
//  }, [state.items]);
  
//   return (
//     <CartDispatchContext.Provider value={dispatch}>
//       <CartStateContext.Provider value={state}>
//         {children}
//       </CartStateContext.Provider>
//     </CartDispatchContext.Provider>
//   );
// };

export default CartProvider;














































// import React, { useReducer, createContext, useEffect, useState } from "react";
// // import useLocalStorage from ''

// const initialState = {
//   isCartOpen: false,
//   items: []
// };

// export const CartStateContext = createContext();
// export const CartDispatchContext = createContext();

// const reducer = (state, action) => {
//   switch (action.type) {
//     case "TOGGLE_CART_POPUP":
//       return {
//         ...state,
//         isCartOpen: !state.isCartOpen
//       };
//     case "ADD_TO_CART":
//       const id = action.payload.cartItem.id;
//       const isOld = state.items.map((item) => item.id).includes(id);
//       let cartItems = null;
//       if (isOld) {
//         const items = state.items.map((item) => {
//           if (item.id === id) {
//             return {
//               ...item,
//               quantity: item.quantity + 1
//             };
//           }
//           return item;
//         });
//         cartItems = [...items];
//       } else {
//         cartItems = [...state.items, action.payload.cartItem];
//       }
//       return {
//         ...state,
//         items: cartItems
//       };
//     case "REMOVE_FROM_CART":
//       const idRemove = action.payload.cartItem.id;
//       const isOldRemove = state.items.map((item) => item.id).includes(idRemove);
//       let cartItemsRemove = null;

//       if (state.items.some(item => item.id === idRemove)) {
//         state.items.map(item => item.id === idRemove && item.quantity > 0 ? item.quantity -= 1 : item.quantity)
//         const newItems = state.items.filter(item => item.quantity > 0)
//         cartItemsRemove = newItems
//       }
//       return {
//         ...state,
//         items: cartItemsRemove
//       };
//     case "CLEAR_CART":
//       return {
//         ...state,
//         ...initialState
//       };
//     default:
//       throw new Error(`Unknown action: ${action.type}`);
//   }
// };

// export const toggleCartPopup = (dispatch) => {
//   return dispatch({
//     type: "TOGGLE_CART_POPUP"
//   });
// };

// export const addToCart = (dispatch, cartItem) => {
//   return dispatch({
//     type: "ADD_TO_CART",
//     payload: {
//       cartItem: cartItem
//     }
//   });
// };

// export const removeFromCart = (dispatch, cartItem) => {
//   return dispatch({
//     type: "REMOVE_FROM_CART",
//     payload: {
//       cartItem: cartItem
//     }
//   });
// };

// export const clearCart = (dispatch) => {
//   return dispatch({
//     type: "CLEAR_CART"
//   });
// };

// const CartProvider = ({ children }) => {

//   var dataCartItems = JSON.parse(localStorage.getItem('cartItems')) || []
//   var dataCartItemsNew = dataCartItems.filter(item => item.quantity!=0)
//   const [persistedCartItems, setPersistedCartItems] = useState(dataCartItemsNew)

//   const persistedCartState = {
//     isCartOpen: false,
//     items: persistedCartItems || []
//   };
  
//   const [state, dispatch] = useReducer(reducer, persistedCartState);

//   useEffect(() => {
//     setPersistedCartItems(state.items);
//     localStorage.setItem("cartItems", JSON.stringify(persistedCartItems))
//  }, [state.items]);
  
//   return (
//     <CartDispatchContext.Provider value={dispatch}>
//       <CartStateContext.Provider value={state}>
//         {children}
//       </CartStateContext.Provider>
//     </CartDispatchContext.Provider>
//   );
// };

// export default CartProvider;














// // import React, { useReducer, createContext, useEffect } from "react";
// // import useLocalStorage from "use-local-storage";

// // const initialState = {
// //   isCartOpen: false,
// //   items: []
// // };

// // export const CartStateContext = createContext();
// // export const CartDispatchContext = createContext();

// // const reducer = (state, action) => {
// //   switch (action.type) {
// //     case "TOGGLE_CART_POPUP":
// //       return {
// //         ...state,
// //         isCartOpen: !state.isCartOpen
// //       };
// //     case "ADD_TO_CART":
// //       const id = action.payload.cartItem.id;
// //       const isOld = state.items.map((item) => item.id).includes(id);
// //       let cartItems = null;
// //       if (isOld) {
// //         const items = state.items.map((item) => {
// //           if (item.id === id) {
// //             return {
// //               ...item,
// //               quantity: item.quantity + 1
// //             };
// //           }
// //           return item;
// //         });
// //         cartItems = [...items];
// //       } else {
// //         cartItems = [...state.items, action.payload.cartItem];
// //       }
// //       return {
// //         ...state,
// //         items: cartItems
// //       };
// //     case "REMOVE_FROM_CART":
// //       return {
// //         ...state,
// //         items: state.items.filter(
// //           (item) => item.id !== action.payload.cartItemId
// //         )
// //       };
// //     case "CLEAR_CART":
// //       return {
// //         ...state,
// //         ...initialState
// //       };
// //     default:
// //       throw new Error(`Unknown action: ${action.type}`);
// //   }
// // };

// // export const toggleCartPopup = (dispatch) => {
// //   return dispatch({
// //     type: "TOGGLE_CART_POPUP"
// //   });
// // };

// // export const addToCart = (dispatch, cartItem) => {
// //   return dispatch({
// //     type: "ADD_TO_CART",
// //     payload: {
// //       cartItem: cartItem
// //     }
// //   });
// // };

// // export const removeFromCart = (dispatch, cartItemId) => {
// //   return dispatch({
// //     type: "REMOVE_FROM_CART",
// //     payload: {
// //       cartItemId: cartItemId
// //     }
// //   });
// // };

// // export const clearCart = (dispatch) => {
// //   return dispatch({
// //     type: "CLEAR_CART"
// //   });
// // };

// // const CartProvider = ({ children }) => {
// //   const [persistedCartItems, setPersistedCartItems] = useLocalStorage(
// //     "cartItems",
// //     []
// //   );

// //   const persistedCartState = {
// //     isCartOpen: false,
// //     items: persistedCartItems || []
// //   };
// //   const [state, dispatch] = useReducer(reducer, persistedCartState);
// //   useEffect(() => {
// //     setPersistedCartItems(state.items);
// //   }, [JSON.stringify(state.items)]);
  
// //   return (
// //     <CartDispatchContext.Provider value={dispatch}>
// //       <CartStateContext.Provider value={state}>
// //         {children}
// //       </CartStateContext.Provider>
// //     </CartDispatchContext.Provider>
// //   );
// // };

// // export default CartProvider;