import React from 'react'
import {Service} from '../../components/public/index'

const Package = () => {
  return (
    <div>
      <Service/>
    </div>
  )
}

export default Package